import { useState, useEffect, useRef } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { RiArrowDropDownLine } from "react-icons/ri";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";

import { useDispatch, useSelector } from "react-redux";
import { Transition } from "@headlessui/react";
import React from "react";
import useAuthInfo from "../../hooks/getUser";
import { loadUser, logout } from "../../redux/actions/UserAction";
import { getAllProducts } from "../../redux/actions/ProductAction";
import { FaHandLizard } from "react-icons/fa";
import { getAllCity } from "../../redux/actions/CityAction";
import useGetAllCity from "../../hooks/getCityList";
import useOutsideClick from "../../hooks/useOutsideClick";
import logo from "../../assets/logo/logo.png";

const Header = () => {
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");
  const [cityDropdownOpen, setCityDropdownOpen] = useState(false);
  const [isTournamentOpen, setIsTournamentOpen] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState("");
  const [selectedActivities, setSelectedActivities] = useState("");
  const [selectedNews, setSelectedNews] = useState("");
  const [selectedContact, setSelectedContact] = useState("");
  const [selectedTournaments, setSelectedTournaments] = useState("");
  const [toggleMenu, setToggleMenu] = useState(false);
  const [navbarOpen, setNavbarOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isActivityOpen, setIsOpen] = useState(false);
  const [isNewsOpen, setIsNewsOpen] = useState(false);
  const [isContactOpen, setIsContactOpen] = useState(false);
  const [isLeaguesOpen, setIsLeaguesOpen] = useState(false);
  const dropdownRef = useRef(null);
  const navbarRef = useRef(null);
  const { userDetails, error, isAuthenticated } = useSelector((state) => {
    return state.user;
  });

  const { loading, citylist: cities } = useGetAllCity();

  let customName;
  let email;

  if (isAuthenticated) {
    // Find the custom:name attribute value
    customName = userDetails["name"];
    email = userDetails["email"];

    console.log("Custom user", email);
  }

  const handleSearchValue = (e) => {
    setSearchValue(e.target.value);
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      navigate(`/store/${searchValue}`);
    }
  };

  let navigate = useNavigate();
  const logoutUser = () => {
    dispatch(logout());
    let path = "/login";
    setIsDropdownOpen(false);
    navigate(path);
  };

  if (isAuthenticated) {
    // Find the custom:name attribute value
    customName = userDetails["name"];
    email = userDetails["email"];
  }
  const closeMenu = () => {
    setToggleMenu(false);
    setNavbarOpen(false);
    setIsDropdownOpen(false);
    setCityDropdownOpen(false);
    setIsNewsOpen(false);
    setIsTournamentOpen(false);
    setIsContactOpen(false);
    setIsLeaguesOpen(false);
  };

  const toggleSearch = () => {
    setSearchOpen(!searchOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const pageLinks = [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "About",
      link: "about",
    },
    {
      text: "Rules",
      link: "rules",
    },
    // {
    //   text: "LeagueInfo",
    //   link: "leagueinfo",
    // },
    // {
    //   text: "Score",
    //   link: "leaguePlayer",
    // },
    {
      text: "City",
      drop: cities?.map((city, index) => ({
        text: city?.name,
        city: city?.name,
        link: `leagueCity/${city.name}`, // Replace this link with the appropriate URL for each city
      })),
    },
    {
      text: "Events",
      link: "events",
    },
    {
      text: "News",
      link: "news",
    },
    {
      text: "Blog",
      link: "blogs",
    },
    {
      text: "Store",
      drop: [
        {
          text: "Sonics",
          img: "images/team-logo-sonics.png",
          link: "store",
        },
        {
          text: "BayArea",
          img: "images/team-logo-hoopitup.png",
          link: "store",
        },
        {
          text: "Sizzle",
          img: "images/team-logo-sizzle.png",
          link: "store",
        },
        {
          text: "ForMen",
          link: "store",
        },
        {
          text: "ForWomen",
          link: "store",
        },
        {
          text: "ForKids",
          link: "store",
        },
      ],
    },
    {
      text: "Blog",
      link: "blog",
    },
    // {
    //   text: "Vlog",
    //   link: "vlog",
    // },
    {
      text: "ContactUs",
      link: "contact",
    },
  ];

  const pageLinksMobile = [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "About",
      link: "about",
    },
    {
      text: "Rules",
      link: "rules",
    },
    // {
    //   text: "LeagueInfo",
    //   link: "leagueinfo",
    // },
    // {
    //   text: "Score",
    //   link: "leaguePlayer",
    // },
    {
      text: "City",
      link: "leagueinfo",
    },
    {
      text: "Events",
      link: "events",
    },
    {
      text: "News",
      link: "news",
    },
    {
      text: "Store",
      link: "store",
    },
    {
      text: "Blog",
      link: "blog",
    },
    // {
    //   text: "Vlog",
    //   link: "vlog",
    // },
    {
      text: "ContactUs",
      link: "contact",
    },
  ];

  const activities = [
    { name: "Leagues", nested: true },
    { name: "Tournament", link: "tournament-details" },
    { name: "Camp", link: "camp-details" },
    { name: "Training", link: "training-program-details" },
    { name: "Clinics", link: "clinics" },
  ];
  const leaguesDetails = [
    { name: "Leagues Details", link: "league-details" },
    { name: "Leagues Live", link: "leagues-score" },
    { name: "League Teams", link: "league-teams" },
    { name: "Past Leagues", link: "past-leagues" },
  ];

  const news = [
    { name: "News", link: "news" },
    { name: "Vlog", link: "vlog" },
    { name: "Blog", link: "blog" },
  ];

  const tournaments = [
    { name: "Tournament Details", link: "tournament-details" },
    { name: "Live Tournament", link: "tournamentsScore" },
    { name: "Tournament Teams", link: "tournament-teams-by-agegroup" },
    { name: "Past Tournaments", link: "past-tournaments" },
  ];

  const contacts = [
    { name: "Contact Us", link: "contact" },
    { name: "SignUp As Partner", link: "signuppartner" },
  ];
  const handleNavbar = () => {
    setToggleMenu(!toggleMenu);
    setNavbarOpen(!navbarOpen);
    setIsDropdownOpen(false);
    setSearchOpen(false);
    setCityDropdownOpen(false);
    setIsNewsOpen(false);
    setIsContactOpen(false);
    setIsTournamentOpen(false);
  };

  const handleSelection = (value) => {
    setSelectedActivity(null);
    setSelectedNews(null);
    setSelectedContact(null);
    setSelectedTournaments(null);
    setSelectedActivities(value);
    setIsOpen(false);
    setNavbarOpen(false);
    setCityDropdownOpen(false);
    setIsNewsOpen(false);
    setIsContactOpen(false);
    setIsTournamentOpen(false);
    setIsLeaguesOpen(false);
    handleCloseDropdown();
  };
  const handleNewsSelection = (value) => {
    setSelectedActivity(null);
    setSelectedContact(null);
    setSelectedNews(value);
    setSelectedActivities(null);
    setSelectedTournaments(null);
    setIsOpen(false);
    setNavbarOpen(false);
    setCityDropdownOpen(false);
    setIsNewsOpen(false);
    setIsContactOpen(false);
    setIsTournamentOpen(false);
    setIsLeaguesOpen(false);
    handleCloseDropdown();
  };
  const handleTournamentSelection = (value) => {
    setSelectedActivity(null);
    setSelectedNews(null);
    setSelectedActivities(null);
    setSelectedTournaments(value);
    setIsOpen(false);
    setNavbarOpen(false);
    setCityDropdownOpen(false);
    setIsNewsOpen(false);
    setIsTournamentOpen(false);
    setIsLeaguesOpen(false);
    handleCloseDropdown();
  };
  const handleContactSelection = (value) => {
    setSelectedActivity(null);
    setSelectedNews(null);
    setSelectedContact(value);
    setSelectedActivities(null);
    setSelectedTournaments(null);
    setIsOpen(false);
    setNavbarOpen(false);
    setCityDropdownOpen(false);
    setIsNewsOpen(false);
    setIsContactOpen(false);
    setIsTournamentOpen(false);
    setIsLeaguesOpen(false);
    handleCloseDropdown();
  };

  const handleNewsToggleDropdown = () => {
    setIsNewsOpen(!isNewsOpen);
    setIsContactOpen(false);
    setCityDropdownOpen(false);
    setIsTournamentOpen(false);
    setIsLeaguesOpen(false);
  };
  const handleTournamentToggleDropdown = () => {
    setIsNewsOpen(false);
    setCityDropdownOpen(false);
    setIsTournamentOpen(!isTournamentOpen);
    setIsContactOpen(false);
    setIsLeaguesOpen(false);
  };
  const handleContactToggleDropdown = () => {
    setIsContactOpen(!isContactOpen);
    setIsNewsOpen(false);
    setCityDropdownOpen(false);
    setIsTournamentOpen(false);
    setIsLeaguesOpen(false);
  };
  const handleToggleDropdown = () => {
    setIsOpen(!isActivityOpen);
    setIsTournamentOpen(false);
    setCityDropdownOpen(false);
    setIsNewsOpen(false);
    setIsContactOpen(false);
    setIsLeaguesOpen(false);
  };
  const handleCitySelection = (event) => {
    setSelectedActivity(event);
    setSelectedActivities(null);
    setSelectedTournaments(null);
    setSelectedNews(null);
    setSelectedContact(null);
    setNavbarOpen(false);
    setIsLeaguesOpen(false);
    handleCloseDropdown();
    handleCityToggleDropdown();
  };
  const handleCityToggleDropdown = () => {
    setCityDropdownOpen(!cityDropdownOpen);
    setIsOpen(false);
    setIsNewsOpen(false);
    setIsContactOpen(false);
    setIsTournamentOpen(false);
    setIsLeaguesOpen(false);
  };

  const handleCloseDropdown = () => {
    setIsOpen(false);
    setIsNewsOpen(false);
    setIsContactOpen(false);
    setCityDropdownOpen(false);
    setIsTournamentOpen(false);
    setToggleMenu(false);
    setNavbarOpen(false);
    setIsLeaguesOpen(false);
  };
  console.log("pageLink", pageLinks);
  // const closeAllDropdowns = () => {
  //   setIsOpen(false);
  //   setIsNewsOpen(false);
  //   setIsContactOpen(false);
  //   setCityDropdownOpen(false);
  //   setIsTournamentOpen(false);
  // };

  // useOutsideClick(dropdownRef, closeAllDropdowns); // Use the custom hook
  const handleMouseEnter = (activity) => {
    if (activity.name === "Leagues") {
      setIsLeaguesOpen(true);
    }
  };

  const handleMouseLeave = () => {
    setIsLeaguesOpen(false);
  };
  useEffect(() => {
    dispatch(getAllCity());
  }, [dispatch]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        navbarRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !navbarRef.current.contains(event.target)
      ) {
        closeMenu();
        handleCloseDropdown();
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <>
      <nav
        ref={navbarRef}
        className="fixed bg-[#181b22] h-[80px] z-[1000] text-[8px] w-full"
      >
        <div
          ref={dropdownRef}
          className={` relative  w-full text-white text-md mt-0 md:mt-[15px]`}
        >
          <div className="relative  w-full px-[25px] mt-[15px] lg:mt-0  ">
            <div className="flex w-full  items-center  justify-between gap-2 ">
              <div className="lg:hidden   flex items-center">
                <button onClick={handleNavbar}>
                  {navbarOpen ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6 text-white"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6 text-white"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M4 6h16M4 12h16M4 18h16"
                      />
                    </svg>
                  )}
                </button>
              </div>
              <div className="max-w-[250px] max-h-[60px]">
                <Link
                  to="/"
                  className="max-w-[350px] h-fit  hidden lg:block gap-1 font-bold justify-center  items-center "
                >
                  <img
                    src={logo}
                    alt="sfbaybasketballarea"
                    className="w-full max-h-[60px] object-cover "
                  />
                </Link>
              </div>
              <div className="hidden   lg:flex gap-1 ">
                <div className="hidden xl:flex  flex-1  border-b-2 border-b-indigo-50 ">
                  <input
                    placeholder="Search"
                    onKeyPress={handleKeyPress}
                    value={searchValue}
                    onChange={handleSearchValue}
                    className="flex-1 text-sm px-2"
                  />

                  <Link to={`/store/${searchValue}`} className="cursor-pointer">
                    <AiOutlineSearch size={30} className="cursor-pointer" />
                  </Link>
                </div>
                <NavLink
                  to={"/"}
                  onClick={handleToggleDropdown}
                  className="px-4 py-2 text-[15px]  hover:text-red-400 whitespace-nowrap"
                >
                  Home
                </NavLink>
                <div className="relative w-fit">
                  <p
                    onClick={handleCityToggleDropdown}
                    onBlur={handleCloseDropdown}
                    className="bg-transparent flex gap-[15px] w-fit  items-center  text-white cursor-pointer   px-4 py-2 rounded border-0 focus:outline-none"
                  >
                    <span className="w-fit text-[15px]">City</span>
                    <IoIosArrowDown
                      size={20}
                      className={`${
                        cityDropdownOpen ? "rotate-180" : "rotate-0"
                      } duration-300 transition-all ease-in-out`}
                    />
                  </p>

                  {cityDropdownOpen && (
                    <div
                      ref={dropdownRef}
                      className="absolute mt-2 bg-white border rounded w-fit z-10 text-[15px] cursor-pointer"
                    >
                      <ul className="max-h-[750px] flex flex-col overflow-auto">
                        {cities.map((value, index) => (
                          <Link
                            to={`leaguecity/${value?.name}`}
                            key={index}
                            className={`text-gray-500 whitespace-nowrap px-4 py-2 w-full cursor-pointer ${
                              selectedActivity === value.name
                                ? "bg-gray-200"
                                : "hover:bg-gray-100"
                            }`}
                            onClick={(e) => {
                              handleCitySelection(value?.name);
                              handleCloseDropdown(); // Close the city dropdown when a city is clicked
                            }}
                          >
                            {value.name}
                          </Link>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
                <div className="relative w-fit">
                  <p
                    onClick={handleToggleDropdown}
                    onBlur={handleCloseDropdown}
                    className="bg-[#181B22] flex items-center  text-white cursor-pointer  gap-2 px-4 py-2 rounded border-0 focus:outline-none"
                  >
                    <span className="min-w-fit text-[15px]">Activities</span>
                    <IoIosArrowDown
                      size={20}
                      className={`${
                        isActivityOpen ? "rotate-180" : "rotate-0"
                      } duration-300 transition-all ease-in-out`}
                    />
                  </p>

                  {isActivityOpen && (
                    <div className="absolute mt-2 text-[15px] bg-white border rounded w-fit z-10 cursor-pointer">
                      <ul className="activities-list relative">
                        {activities.map((activity, index) => (
                          <li
                            key={index}
                            onMouseEnter={() => handleMouseEnter(activity)}
                            onMouseLeave={handleMouseLeave}
                            className={`text-gray-500 px-4 py-2 w-full hover:bg-gray-100 cursor-pointer ${
                              selectedActivities === activity.link
                                ? "bg-gray-300"
                                : ""
                            }`}
                          >
                            <Link to={activity.link}>{activity.name}</Link>
                            {activity.nested && isLeaguesOpen && (
                              <ul className="nested-dropdown absolute left-full whitespace-nowrap top-0 ml-2 bg-white border border-gray-200 shadow-lg">
                                {leaguesDetails.map((leagueDetail, index) => (
                                  <li
                                    key={index}
                                    className="text-gray-500 px-4 py-2 hover:bg-gray-100"
                                  >
                                    <Link to={leagueDetail.link}>
                                      {leagueDetail.name}
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                            )}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
                <div className="relative w-fit">
                  <p
                    onClick={handleTournamentToggleDropdown}
                    onBlur={handleCloseDropdown}
                    className="bg-[#181B22] flex items-center  text-white cursor-pointer  gap-2 px-4 py-2 rounded border-0 focus:outline-none"
                  >
                    <span className="min-w-fit text-[15px]">Tournament</span>
                    <IoIosArrowDown
                      size={20}
                      className={`${
                        isTournamentOpen ? "rotate-180" : "rotate-0"
                      } duration-300 transition-all ease-in-out`}
                    />
                  </p>

                  {isTournamentOpen && (
                    <div className="absolute mt-2 text-[15px] bg-white border rounded w-fit z-10 cursor-pointer">
                      <ul className="max-h-[250px]  flex flex-col overflow-auto">
                        {tournaments.map((value, index) => (
                          <Link
                            to={`${value.link}`}
                            key={index}
                            className={`text-gray-500 whitespace-nowrap px-4 py-2 w-full hover:bg-gray-100 cursor-pointer ${
                              selectedTournaments === value.link
                                ? "bg-gray-300"
                                : ""
                            }`}
                            onClick={(e) =>
                              handleTournamentSelection(value.link)
                            }
                          >
                            {value.name}
                          </Link>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
                <div className="relative w-fit">
                  <p
                    onClick={handleNewsToggleDropdown}
                    onBlur={handleCloseDropdown}
                    className="bg-[#181B22] flex items-center  text-white cursor-pointer  gap-2 px-4 py-2 rounded border-0 focus:outline-none"
                  >
                    <span className="min-w-fit text-[15px]">News</span>
                    <IoIosArrowDown
                      size={20}
                      className={`${
                        isNewsOpen ? "rotate-180" : "rotate-0"
                      } duration-300 transition-all ease-in-out`}
                    />
                  </p>

                  {isNewsOpen && (
                    <div className="absolute mt-2 text-[15px] bg-white border rounded w-fit z-10 cursor-pointer">
                      <ul className="max-h-[250px]  flex flex-col overflow-auto">
                        {news.map((value, index) => (
                          <Link
                            to={`${value.link}`}
                            key={index}
                            className={`text-gray-500 px-4 py-2 w-full hover:bg-gray-100 cursor-pointer ${
                              selectedNews === value.link ? "bg-gray-300" : ""
                            }`}
                            onClick={(e) => handleNewsSelection(value.link)}
                          >
                            {value.name}
                          </Link>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
                <div className="relative w-fit">
                  <p
                    onClick={handleContactToggleDropdown}
                    onBlur={handleCloseDropdown}
                    className="bg-[#181B22] flex items-center  text-white cursor-pointer  gap-2 px-4 py-2 rounded border-0 focus:outline-none"
                  >
                    <span className="min-w-fit text-[15px]">ContactUs</span>
                    <IoIosArrowDown
                      size={20}
                      className={`${
                        isContactOpen ? "rotate-180" : "rotate-0"
                      } duration-300 transition-all ease-in-out`}
                    />
                  </p>

                  {isContactOpen && (
                    <div className="absolute mt-2 text-[15px] bg-white border rounded w-fit z-10 cursor-pointer">
                      <ul className="max-h-[250px]  flex flex-col overflow-auto">
                        {contacts.map((value, index) => (
                          <Link
                            to={`${value.link}`}
                            key={index}
                            className={`text-gray-500 px-4 py-2 w-full hover:bg-gray-100 cursor-pointer whitespace-nowrap ${
                              selectedContact === value.link
                                ? "bg-gray-300"
                                : ""
                            }`}
                            onClick={(e) => handleContactSelection(value.link)}
                          >
                            {value.name}
                          </Link>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
                <NavLink
                  to={"/players"}
                  className="px-4 py-2 text-[15px]  hover:text-red-400 whitespace-nowrap"
                >
                  Players
                </NavLink>
                <NavLink
                  to={"/rules"}
                  className="px-4 py-2 text-[15px]  hover:text-red-400 whitespace-nowrap"
                >
                  Rules
                </NavLink>

                <NavLink
                  to={"/events"}
                  className="px-4 py-2 text-[15px]  hover:text-red-400 whitespace-nowrap"
                >
                  Events
                </NavLink>

                {/* <NavLink
                  to={"/contact"}
                  className="px-4 py-2 text-[15px]  hover:text-red-400 whitespace-nowrap"
                >
                  Contactus
                </NavLink> */}
                <NavLink
                  to={"/about"}
                  className="px-4 py-2 text-[15px]  hover:text-red-400 whitespace-nowrap"
                >
                  About
                </NavLink>
                <br />
              </div>

              <div className="w-fit bg-green-10 flex gap-2 z-50 ">
                {searchOpen && (
                  <div className="lg:hidden flex items-center justify-center">
                    <div className="w-full md:max-w-[300px] relative">
                      <input
                        placeholder="Search"
                        onKeyPress={handleKeyPress}
                        value={searchValue}
                        onChange={handleSearchValue}
                        className="w-full px-4 text-sm p-2 rounded bg-gray-500 bg-opacity-20  text-white"
                      />
                      <Link
                        to={`/store/${searchValue}`}
                        onClick={toggleSearch}
                        className="absolute top-0 bottom-[10px] right-2 flex items-center justify-center mt-[10px] text-white"
                      >
                        <AiOutlineSearch size={24} />
                      </Link>
                    </div>
                  </div>
                )}
                <div className="lg:hidden flex items-center justify-end ">
                  <div
                    className="text-white flex item-center w-fit justify-center cursor-pointer"
                    onClick={toggleSearch}
                  >
                    {searchOpen ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-6 h-6 text-white"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    ) : (
                      <AiOutlineSearch size={24} />
                    )}
                  </div>
                </div>

                {/* Mobile search input */}

                {isAuthenticated && (
                  <div className=" items-center  w-[50px] h-[50px] overflow-hidden   text-white flex ">
                    <div className="flex items-center relative">
                      <div className="relative inline-block text-left group ">
                        <img
                          className="w-[50px] h-[50px] rounded-full cursor-pointer"
                          src="https://media.istockphoto.com/id/1337144146/vector/default-avatar-profile-icon-vector.jpg?s=612x612&w=0&k=20&c=BIbFwuv7FxTWvh5S3vB6bkT0Qv8Vn8N5Ffseq84ClGI="
                          alt="User Avatar"
                          onClick={toggleDropdown}
                          // onClick={handleNavbar}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {isDropdownOpen && (
                  <div className="origin-top-right absolute top-[68px] z-[100] right-2  min-w-36 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div
                      className="py-1"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="options-menu"
                    >
                      <NavLink
                        to="/profile"
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                        role="menuitem"
                        onClick={() => {
                          setToggleMenu(false);
                          setNavbarOpen(false);
                          toggleDropdown();
                        }}
                      >
                        Profile
                      </NavLink>
                      {/* Order option */}
                      <Link
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                        role="menuitem"
                        onClick={() => {
                          setToggleMenu(false);
                          setNavbarOpen(false);
                          toggleDropdown();
                        }}
                      >
                        {customName}
                      </Link>
                      <span
                        onClick={() => {
                          setToggleMenu(false);
                          setNavbarOpen(false);
                          toggleDropdown();
                        }}
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                        role="menuitem"
                      >
                        {email}
                      </span>
                      <NavLink
                        to="/order"
                        onClick={() => {
                          setToggleMenu(false);
                          setNavbarOpen(false);
                          toggleDropdown();
                        }}
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                        role="menuitem"
                      >
                        Order
                      </NavLink>

                      <NavLink
                        to="/cart"
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                        role="menuitem"
                        onClick={() => {
                          setToggleMenu(false);
                          setNavbarOpen(false);
                          toggleDropdown();
                        }}
                      >
                        Cart
                      </NavLink>

                      <button
                        className="block w-full text-[12px] text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                        role="menuitem"
                        onClick={logoutUser}
                      >
                        Logout
                      </button>
                    </div>
                  </div>
                )}
                {!isAuthenticated && (
                  <NavLink
                    className="  border-2 text-[12px] text-center flex items-center justify-center p-2 w-fit hover:opacity-80 transition-all duration-400 ease-in-out text-md  bg-[#fec749] text-black border-[#fec749] rounded-md "
                    to="/login"
                  >
                    Login/Register
                  </NavLink>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* mobile navigation */}
        <div
          ref={dropdownRef}
          className={`fixed z-40 w-full mt-[15px] bg-gray-100 overflow-hidden flex flex-col lg:hidden gap-2  origin-top duration-700 ${
            !toggleMenu ? "h-0" : "h-full"
          }`}
        >
          <div className="px-3">
            <div className="flex flex-col gap-2 font-bold tracking-wider">
              <div className="hidden xl:flex flex-1 mx-10 border-b-2 border-b-indigo-50 ">
                <input
                  placeholder="Search"
                  onKeyPress={handleKeyPress}
                  value={searchValue}
                  onChange={handleSearchValue}
                  onClick={handleNavbar}
                  className="flex-1"
                />

                <Link to={`/store/${searchValue}`} className="cursor-pointer">
                  <AiOutlineSearch size={30} className="cursor-pointer" />
                </Link>
              </div>

              <NavLink
                to={"/"}
                onClick={handleCloseDropdown}
                className="px-4 py-2 text-[15px]  hover:text-red-400 whitespace-nowrap"
              >
                Home
              </NavLink>
              <div className="relative w-fit">
                <p
                  onClick={handleCityToggleDropdown}
                  onBlur={handleCloseDropdown}
                  className="bg-transparent flex gap-[15px] w-fit  items-center  text-black cursor-pointer   px-4 py-2 rounded border-0 focus:outline-none"
                >
                  <span className="w-fit text-[15px]">City</span>
                  <IoIosArrowDown
                    size={20}
                    className={`${
                      cityDropdownOpen ? "rotate-180" : "rotate-0"
                    } duration-300 transition-all ease-in-out`}
                  />
                </p>

                {cityDropdownOpen && (
                  <div className="absolute mt-2 bg-white border rounded min-w-fit z-10 cursor-pointer">
                    <ul className="max-h-[750px] text-[15px]  flex flex-col overflow-auto">
                      {cities.map((value, index) => (
                        <Link
                          to={`leaguecity/${value?.name}`}
                          key={index}
                          className={`text-gray-500 whitespace-nowrap px-4 py-2 min-w-fit hover:bg-gray-100 cursor-pointer  ${
                            selectedActivity === value.name
                              ? "bg-gray-200"
                              : "hover:bg-gray-100"
                          }`}
                          onClick={(e) => handleCitySelection(value?.name)}
                        >
                          {value.name}
                        </Link>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
              <div className="relative w-fit">
                <p
                  value={selectedActivity}
                  onClick={handleToggleDropdown}
                  onBlur={handleCloseDropdown}
                  className="bg-transparent  flex items-center  text-black cursor-pointer  gap-2 px-4 py-2 rounded border-0 focus:outline-none"
                >
                  <span className="min-w-fit text-[15px]">Activities</span>
                  <IoIosArrowDown
                    size={20}
                    className={`${
                      isActivityOpen ? "rotate-180" : "rotate-0"
                    } duration-300 transition-all ease-in-out`}
                  />
                </p>

                {isActivityOpen && (
                  <div className="absolute mt-2 bg-white border text-[15px] rounded w-fit z-10 cursor-pointer">
                    {/* <ul className="max-h-[250px]  flex flex-col overflow-auto">
                     {activities.map((value, index) => (
                        <Link
                          to={`${value.link}`}
                          key={index}
                          className={`text-gray-500 px-4 py-2 w-full  hover:bg-gray-100 cursor-pointer  ${selectedActivities === value.link ? 'bg-gray-300' : ''
                            }`}
                          onClick={() => handleSelection(value.link)}
                        >
                          {value.name}
                        </Link>
                      ))} 
                        {activities.map((activity, index) => (
                <li
                  key={index}
                  onMouseEnter={() => handleMouseEnter(activity)}
                  onMouseLeave={handleMouseLeave}
                  className={`text-gray-500 px-4 py-2 w-full hover:bg-gray-100 cursor-pointer ${selectedActivities === activity.link ? 'bg-gray-300' : ''
                  }`}
                >
                  <Link
                   
                  to={activity.link}>{activity.name}</Link>
                  {activity.nested && isLeaguesOpen && (
                    <ul className="nested-dropdown">
                      {leaguesDetails.map((leagueDetail, index) => (
                        <li key={index}>
                          <Link to={leagueDetail.link}>{leagueDetail.name}</Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
                      </ul> */}
                    <ul className="activities-list relative">
                      {activities.map((activity, index) => (
                        <li
                          key={index}
                          onMouseEnter={() => handleMouseEnter(activity)}
                          onMouseLeave={handleMouseLeave}
                          className={`text-gray-500 px-4 py-2 w-full hover:bg-gray-100 cursor-pointer ${
                            selectedActivities === activity.link
                              ? "bg-gray-300"
                              : ""
                          }`}
                        >
                          <Link to={activity.link}>{activity.name}</Link>
                          {activity.nested && isLeaguesOpen && (
                            <ul className="nested-dropdown absolute left-full whitespace-nowrap top-0 ml-2 bg-white border border-gray-200 shadow-lg">
                              {leaguesDetails.map((leagueDetail, index) => (
                                <li
                                  key={index}
                                  className="text-gray-500 px-4 py-2 hover:bg-gray-100"
                                >
                                  <Link to={leagueDetail.link}>
                                    {leagueDetail.name}
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
              <div className="relative w-fit">
                <p
                  onClick={handleNewsToggleDropdown}
                  onBlur={handleCloseDropdown}
                  className="bg-transparent flex gap-[15px] w-fit  items-center  text-black cursor-pointer   px-4 py-2 rounded border-0 focus:outline-none"
                >
                  <span className="w-fit text-[15px]">News</span>
                  <IoIosArrowDown
                    size={20}
                    className={`${
                      isNewsOpen ? "rotate-180" : "rotate-0"
                    } duration-300 transition-all ease-in-out`}
                  />
                </p>

                {isNewsOpen && (
                  <div className="absolute mt-2 bg-white border rounded min-w-fit z-10 cursor-pointer">
                    <ul className="max-h-[750px] text-[15px]  flex flex-col overflow-auto">
                      {news.map((value, index) => (
                        <Link
                          to={`${value.link}`}
                          key={index}
                          className={`text-gray-500 px-4 py-2 min-w-fit hover:bg-gray-100 cursor-pointer  ${
                            selectedNews === value.name
                              ? "bg-gray-200"
                              : "hover:bg-gray-100"
                          }`}
                          onClick={(e) => handleNewsSelection(value?.name)}
                        >
                          {value.name}
                        </Link>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
              <div className="relative w-fit">
                <p
                  onClick={handleTournamentToggleDropdown}
                  onBlur={handleCloseDropdown}
                  className="bg-transparent flex gap-[15px] w-fit  items-center  text-black cursor-pointer   px-4 py-2 rounded border-0 focus:outline-none"
                >
                  <span className="w-fit text-[15px]">Tournament</span>
                  <IoIosArrowDown
                    size={20}
                    className={`${
                      isTournamentOpen ? "rotate-180" : "rotate-0"
                    } duration-300 transition-all ease-in-out`}
                  />
                </p>

                {isTournamentOpen && (
                  <div className="absolute mt-2 bg-white border rounded min-w-fit z-10 cursor-pointer">
                    <ul className="max-h-[750px] text-[15px]  flex flex-col overflow-auto">
                      {tournaments.map((value, index) => (
                        <Link
                          to={`${value.link}`}
                          key={index}
                          className={`text-gray-500 whitespace-nowrap px-4 py-2 min-w-fit hover:bg-gray-100 cursor-pointer  ${
                            selectedTournaments === value.name
                              ? "bg-gray-200"
                              : "hover:bg-gray-100"
                          }`}
                          onClick={(e) =>
                            handleTournamentSelection(value?.name)
                          }
                        >
                          {value.name}
                        </Link>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
              <div className="relative w-fit">
                <p
                  onClick={handleContactToggleDropdown}
                  onBlur={handleCloseDropdown}
                  className="bg-transparent flex gap-[15px] w-fit  items-center  text-black cursor-pointer   px-4 py-2 rounded border-0 focus:outline-none"
                >
                  <span className="w-fit text-[15px]">ContactUs</span>
                  <IoIosArrowDown
                    size={20}
                    className={`${
                      isContactOpen ? "rotate-180" : "rotate-0"
                    } duration-300 transition-all ease-in-out`}
                  />
                </p>

                {isContactOpen && (
                  <div className="absolute mt-2 bg-white border rounded min-w-fit z-10 cursor-pointer">
                    <ul className="max-h-[750px] text-[15px]  flex flex-col overflow-auto">
                      {contacts.map((value, index) => (
                        <Link
                          to={`${value.link}`}
                          key={index}
                          className={`text-gray-500 px-4 py-2 min-w-fit hover:bg-gray-100 cursor-pointer whitespace-nowrap ${
                            selectedContact === value.name
                              ? "bg-gray-200"
                              : "hover:bg-gray-100"
                          }`}
                          onClick={(e) => handleContactSelection(value?.name)}
                        >
                          {value.name}
                        </Link>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
              <NavLink
                to={"/about"}
                onClick={handleCloseDropdown}
                className="px-4 py-2 text-[15px]  hover:text-red-400 whitespace-nowrap"
              >
                About
              </NavLink>

              <NavLink
                to={"/rules"}
                onClick={handleCloseDropdown}
                className="px-4 py-2 text-[15px]  hover:text-red-400 whitespace-nowrap"
              >
                Rules
              </NavLink>

              <NavLink
                to={"/events"}
                onClick={handleCloseDropdown}
                className="px-4 py-2 text-[15px]  hover:text-red-400 whitespace-nowrap"
              >
                Events
              </NavLink>
              <NavLink
                to={"/blog"}
                onClick={handleCloseDropdown}
                className="px-4 py-2 text-[15px]  hover:text-red-400 whitespace-nowrap"
              >
                Blog
              </NavLink>
              {/* <NavLink
                to={"/contact"}
                onClick={handleCloseDropdown}
                className="px-4 py-2 text-[15px]  hover:text-red-400 whitespace-nowrap"
              >
                ContactUs
              </NavLink> */}
              <NavLink
                to={"/about"}
                onClick={handleCloseDropdown}
                className="px-4 py-2 text-[15px]  hover:text-red-400 whitespace-nowrap"
              >
                About
              </NavLink>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
