import React, { useState, useEffect } from 'react';
import { AiOutlineGooglePlus } from 'react-icons/ai';
import { ImFacebook } from 'react-icons/im';
import { IoLogoTwitter } from 'react-icons/io';
import { SiInstagram } from 'react-icons/si';
import { NavLink } from 'react-router-dom';
import { API } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import * as queries from '../../graphql/queries';
import getLink from "../../utils/getLinks";
import Loader from '../../Components/Loader';

const HeroPage = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const result = await API.graphql({ query: queries.listCarouselImages });
        const fetchedImages = result?.data?.listCarouselImages?.items || [];

        const fetchedImagesWithLinks = await Promise.all(
          fetchedImages.map(async (img) => {
            const url = await getLink(img.imageLink);
            return url;
          })
        );

        setImages(fetchedImagesWithLinks);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching images:", error);
        setIsLoading(false);
      }
    };

    fetchImages();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000); // Change the image every 3 seconds

    return () => clearInterval(interval); // Clear the interval on component unmount
  }, [images.length]);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="relative text-4xl hero-page   overflow-hidde">
      <div className="relative w-full h-full overflow-hidden rounded-lg">
        {images.map((image, index) => (
          <div
            key={index}
            className={`absolute w-full h-full transition-transform duration-700 ease-in-out ${index === currentIndex ? 'translate-x-0' : index < currentIndex ? '-translate-x-full' : 'translate-x-full'}`}
          >
            <img
              src={image}
              className="object-cover object-center w-full h-full" // Use w-full and h-full to cover the entire space
              alt={`Slide ${index + 1}`}
              loading="lazy" // Add lazy loading for better performance
            />
          </div>
        ))}
      </div>

      <div className='absolute flex flex-col   sm:flex-row  sm:items-center  h-full w-full justify-between bg-black bg-opacity-40 z-10 inset-0'>
        <div className="flex flex-col w-fit  my-auto md:justify-between ">
          <div className="lg:pl-40 p-5 md:pl-20 pl-2">
            <span className="italic font-black text-4xl"> {/* Added text-4xl here */}
              <span>&quot;</span>
              <span className="md:pl-10 sm:pl-2">
                <div className="text-4xl lg:text-6xl "> YOU DON'T</div> {/* Increased to text-5xl */}
                <div className="text-4xl lg:text-6xl"> DESERVE TO</div> {/* Increased to text-5xl */}
                <div className="text-4xl lg:text-6xl">
                  WIN!{" "}
                  <span className="text-3xl text-red-500 whitespace-nowrap md:pl-20">
                    IF YOU DON'T PRACTICE
                  </span>
                </div>
              </span>
            </span>
            <div className="flex flex-col md:flex-row justify-center items-center mt-5 gap-5 sm:gap-10">
              <NavLink
                to="/signupleague"
                className="text-xl text-[#fec749] uppercase text-center  border-2 p-2 border-[#fec749] rounded-md w-60 whitespace-nowrap"
              >
                join our team
              </NavLink>
              <NavLink
                to="/register"
                className="text-xl uppercase w-60 text-center mr-2 border-2 p-2 bg-[#fec749] text-black border-[#fec749] rounded-md whitespace-nowrap"
              >
                register
              </NavLink>
            </div>
          </div>
        </div>
        <div className="flex  md:justify-end mt-5  justify-center items-center">
          <div className="text-gray-400 flex sm:flex-col   text-xl gap-4">
            <NavLink>
              <ImFacebook className="mx-2 inline" />
            </NavLink>
            <NavLink>
              <IoLogoTwitter className="mx-2 inline" />
            </NavLink>
            <NavLink>
              <AiOutlineGooglePlus className="mx-2 inline" />
            </NavLink>
            <NavLink>
              <SiInstagram className="mx-2 inline" />
            </NavLink>
          </div>
        </div>
      </div>

    </div>
  );
};

export default HeroPage;
